import type { QueryClient } from '@tanstack/react-query'
import sizeof from 'object-sizeof'

export const customInfo = (...args: unknown[]): void => {
	if (process.env.NEXT_PUBLIC_ENV !== 'production') {
		const styles = [
			'color: white; background: green; padding: 2px 4px; border-radius: 3px;', // Green badge
			'color: black; background: yellow; padding: 2px 4px; border-radius: 3px;', // Yellow badge
		]
		console.info(
			`%c🌟 DEV LOG 🌟%c ${new Date().toLocaleTimeString()} `,
			styles[0],
			styles[1],
			...args,
		)
	}
}

export const measureCacheSize = (queryClient: QueryClient) => {
	const allQueriesData = queryClient.getQueriesData({})
	return sizeof(allQueriesData)
}

export const measureCacheSizeInMB = (queryClient: QueryClient) => {
	const allQueriesData = queryClient.getQueriesData({})
	const sizeInBytes = new Blob([JSON.stringify(allQueriesData)]).size
	const sizeInMB = sizeInBytes / (1024 * 1024) // Convert bytes to MB
	return sizeInMB
}
