import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { measureCacheSizeInMB } from '@/utils/devUtils' // The utility function

export const useMemoryMonitor = (interval = 100_000) => {
	const queryClient = useQueryClient()

	useEffect(() => {
		const intervalId = setInterval(() => {
			const cacheSize = measureCacheSizeInMB(queryClient)
			console.info(`React Query Cache Size: ${cacheSize} MBs`)
		}, interval)

		return () => clearInterval(intervalId) // Clean up on unmount
	}, [queryClient, interval])
}
