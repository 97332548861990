import { logger } from '@/utils/logger'
import type { TypedSupabaseClient } from '@/lib/supabase.types'
import type { DatabaseRow, DatabaseType } from '@/types/database.table.types'
import type { PostgrestSingleResponse } from '@supabase/supabase-js'

type CrewMemberType = DatabaseType<'crew_members'>

export const fetchCrewMembers = (client: TypedSupabaseClient) => {
	const query = client.from('crew_members').select('*').throwOnError().order('first_name')

	return query
}

export const fetchCrewMemberById = (client: TypedSupabaseClient, id: string) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('id', id)
		.returns<DatabaseRow<'crew_members'>>()
		.throwOnError()
		.single()
}

export const fetchCrewMemberByUserId = (client: TypedSupabaseClient, userId: string) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('profile_id', userId)
		.returns<DatabaseRow<'crew_members'>>()
		.throwOnError()
		.single()
}

export const fetchCrewMemberByEmail = async (
	client: TypedSupabaseClient,
	email: string,
	maybeSingle?: boolean,
): Promise<DatabaseRow<'crew_members'> | null> => {
	const query = client.from('crew_members').select('*').eq('email', email)

	const result = maybeSingle ? await query.maybeSingle() : await query.single()

	if (result.error) {
		logger.error('Error fetching crew members:', email, result.error)
		throw new Error(result.error.message)
	}
	return result.data
}

export const fetchDispatchableCrewMembersCount = (
	client: TypedSupabaseClient,
	groups?: string[],
) => {
	const query = client
		.from('crew_members')
		.select('*', { count: 'exact', head: true })
		.eq('is_dispatchable', true)
		.throwOnError()

	if (groups?.length) {
		query.in('group_id', groups)
	}

	return query
}

export const fetchDispatchableCrewMembers = (client: TypedSupabaseClient) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('is_dispatchable', true)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.throwOnError()
		.returns<DatabaseRow<'crew_members'>[]>()
}

export const fetchMaintenanceCrewMembers = (client: TypedSupabaseClient) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('is_maintenance', true)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.throwOnError()
		.returns<DatabaseRow<'crew_members'>[]>()
}

export const fetchCrewMembersOnLeave = async (client: TypedSupabaseClient) => {
	const { data: crewMembers, error } = await client
		.from('crew_members')
		.select('*')
		.eq('leave_status', 'Away')
		.order('first_name', { ascending: true })
		.returns<DatabaseRow<'crew_members'>[]>()

	if (error) {
		logger.error('Error fetching crew members on leave:', error)
		throw new Error(error.message)
	}
	return crewMembers
}

export const fetchCrewLeaders = async (client: TypedSupabaseClient) => {
	const result: PostgrestSingleResponse<DatabaseRow<'crew_members'>[]> = await client
		.from('crew_members')
		.select('*')
		.eq('is_crew_leader', true)
		.neq('is_dispatchable', false)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.returns<DatabaseRow<'crew_members'>[]>()

	if (result.error) {
		logger.error('Error fetching crew leaders:', result.error)
		throw result.error
	}
	return result.data
}

// Mutations

export const createCrewMember = async (
	client: TypedSupabaseClient,
	memberData: CrewMemberType['Insert'],
) => {
	const { data, error } = await client
		.from('crew_members')
		.insert([{ ...memberData }])
		.select()

	if (error) {
		logger.error('Error creating crew member!!:', error)
		throw new Error(error.message)
	}
	return data
}

export const deleteCrewMember = async (client: TypedSupabaseClient, crewMemberId: string) => {
	const { error } = await client.from('crew_members').delete().eq('id', crewMemberId).single()

	if (error) {
		logger.error('Error deleting crew member:', error)
		throw new Error(error.message)
	}
}
