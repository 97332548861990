'use client'

import {
	colorsTuple,
	createTheme,
	MantineProvider,
	type MantineThemeOverride,
	virtualColor,
} from '@mantine/core'
import type { FC, ReactNode } from 'react'
import { Nunito, Outfit } from 'next/font/google'
import { useMemoryMonitor } from '@/hooks/useMemoryMonitor'

const outfit = Outfit({ subsets: ['latin'] })
const nunito = Nunito({ subsets: ['latin'] })

const theme: MantineThemeOverride = createTheme({
	autoContrast: true,
	fontFamily: nunito.style.fontFamily,
	headings: { fontFamily: outfit.style.fontFamily, fontWeight: '500' },
	primaryColor: 'teal',
	colors: {
		bodyLight: colorsTuple('#fff'),
		bodyDark: colorsTuple('#242424'),
		body: virtualColor({
			name: 'body',
			light: 'bodyLight',
			dark: 'bodyDark',
		}),
		primaryTextLight: colorsTuple('#12b886'),
		primaryTextDark: colorsTuple('#38b9a9'),
		primaryText: virtualColor({
			name: 'primaryText',
			light: 'primaryTextLight',
			dark: 'primaryTextDark',
		}),
		dividerDimLight: colorsTuple('#868E9626'),
		dividerDimDark: colorsTuple('#868E961A'),
		dividerDim: virtualColor({
			name: 'dividerDim',
			light: 'dividerDimLight',
			dark: 'dividerDimDark',
		}),
		dividerStrongLight: colorsTuple('#2e2e2e1a'),
		dividerStrongDark: colorsTuple('#000'),
		dividerStrong: virtualColor({
			name: 'dividerStrong',
			light: 'dividerStrongLight',
			dark: 'dividerStrongDark',
		}),
		brightLight: colorsTuple('#141414'),
		brightDark: colorsTuple('#fff'),
		bright: virtualColor({
			name: 'bright',
			light: 'brightLight',
			dark: 'brightDark',
		}),
		bodyShadedLight: colorsTuple('#f3f4f4'),
		bodyShadedDark: colorsTuple('#2e2f2f'),
		bodyShaded: virtualColor({
			name: 'bodyShaded',
			light: 'bodyShadedLight',
			dark: 'bodyShadedDark',
		}),
		glassShadedLight: colorsTuple('#868E961A'),
		glassShadedDark: colorsTuple('#868e961a'),
		glassShaded: virtualColor({
			name: 'glassShaded',
			light: 'glassShadedLight',
			dark: 'glassShadedDark',
		}),
	},
	components: {
		InputLabel: {
			defaultProps: {
				// ff: outfit.style.fontFamily,
				// faW: '500',
			},
		},
	},
})

const ClientThemeWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	useMemoryMonitor()
	return (
		<MantineProvider
			// forceColorScheme='dark'
			theme={theme}
		>
			{children}
		</MantineProvider>
	)
}

export default ClientThemeWrapper
