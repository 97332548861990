'use client'
import { type ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { supabaseBrowserInstance } from '@/lib/supabaseBrowser'
import type { DatabaseRow } from '@/types/database.table.types'
import type { Session, User } from '@supabase/supabase-js'
import { fetchCrewMemberByUserId } from '@/queries/crewMemberQueries'
import { fetchTeamById } from '@/queries/teamQueries'
import { useQuery } from '@supabase-cache-helpers/postgrest-react-query'

interface AuthContextType {
	user: User | undefined | null
	teamId: string
	team: DatabaseRow<'teams'> | undefined
	session: Session | null
	actingAsCrewMember: DatabaseRow<'crew_members'> | null
	betaAccess: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<User | undefined | null>(undefined)
	const [actingAsCrewMember, setActingAsCrewMember] = useState<DatabaseRow<'crew_members'> | null>(
		null,
	)
	const [session, setSession] = useState<Session | null>(null)

	// Initial session check
	useEffect(() => {
		const checkSession = async () => {
			const {
				data: { session },
			} = await supabaseBrowserInstance.auth.getSession()
			setSession(session)
			setUser(session?.user || null)
		}
		void checkSession()

		// Set up auth listener
		const { data: authListener } = supabaseBrowserInstance.auth.onAuthStateChange(
			(_event, newSession) => {
				// logger.info(`🔒 Auth event: ${event}`)
				setSession(newSession)
				setUser(newSession?.user || null)
			},
		)

		return () => {
			authListener?.subscription.unsubscribe()
		}
	}, [])

	// Fetch crew member when user changes
	useEffect(() => {
		const fetchActingAsCrewMember = async () => {
			if (user) {
				const { data: crewMember } = (await fetchCrewMemberByUserId(
					supabaseBrowserInstance,
					user.id,
				)) as { data: DatabaseRow<'crew_members'> }
				setActingAsCrewMember(crewMember)
			}
		}
		void fetchActingAsCrewMember()
	}, [user])

	const { data: team } = useQuery(
		fetchTeamById(supabaseBrowserInstance, actingAsCrewMember?.team_id || ''),
		{ enabled: !!actingAsCrewMember?.team_id },
	)

	// Memoize the context value to prevent unnecessary rerenders
	const value = {
		user,
		teamId: actingAsCrewMember?.team_id || '',
		team: team || undefined,
		session,
		actingAsCrewMember,
		betaAccess: team?.beta_access || false,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider')
	}
	return context
}
